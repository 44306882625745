.navbar {
    margin-bottom: 0px;
    color: #fff;
}

.navbar-default .navbar-nav > li, footer {
    a, a:visited {
        color: #ccc;
    }
    a:hover {
        color: #fff;
    }
    .dropdown-menu {
        a, a:visited {
            color: #666;
        }
        a:hover {
            color: $brand-primary;
        }
    }
}

.navbar-brand {
    padding: 15px 15px;
    img {
        height: 100%;
    }
}

footer {
    border-top: solid 1px $brand-primary;
    padding: 20px 0;
    color: #fff;
}
